/*
 * @Description: 数据统计
 * @Author: ChenXueLin
 * @Date: 2022-03-22 09:29:26
 * @LastEditTime: 2022-05-27 15:07:14
 * @LastEditors: ChenXueLin
 */
import http from "e6-base-core/axios/config";
import { e3Api } from "@/config/api";
const {
  host = "",
  E3_BL_CALLCENTER = "", // 8802
  E3_BL_EWOS = "",
  E3_AG = ""
} = e3Api;
/******客服工作量统计*******/
//获取表头配置
export function getFirstAndSecondClass(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/workload/getFirstAndSecondClass`,
    method: "post",
    data
  });
}
//导出数据
export function exportWorkload(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/workload/export`,
    method: "post",
    data
  });
}
//获取工作量统计数据
export function getWorkload(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/workload/getCount`,
    method: "post",
    data
  });
}
//获取工作量统计数据
export function getCustomerReportList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/workload/getCustomerReportList`,
    method: "post",
    data
  });
}
//导出工作量统计数据
export function exportCustomerReport(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/workload/exportCustomerReport`,
    method: "post",
    data
  });
}
/******质检报告*******/
//获取质检报告列表
export function inspectionReportList(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/inspection/report/listPage`,
    method: "post",
    data
  });
}

//获取质检人员列表
export function inspectionListPeopleName(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/inspection/report/listPeopleName`,
    method: "post",
    data
  });
}
//导出质检报告
export function inspectionReportExport(data) {
  return http({
    url: `${host}${E3_BL_CALLCENTER}/inspection/report/export`,
    method: "post",
    data
  });
}
/******统计报表-运维中心*******/
//新装明细报告列表
export function newInstallDetailsReportList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/detail/install/listPage`,
    method: "post",
    data
  });
}
//新装明细报告导出
export function newInstallDetailsReportExport(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/detail/install/export`,
    method: "post",
    data
  });
}

//维修明细报告列表
export function maintainDetailsReportList(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/cssTaskinfo/maintain/listPage`,
    method: "post",
    data
  });
}
//维修明细报告导出
export function maintainDetailsReportExport(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/taskExport/maintain/export`,
    method: "post",
    data
  });
}

/******客户服务统计报表*******/
//初装回访统计报表
export function firstInstallReturnVisitStatisticsReportList(data) {
  return http({
    url: `${host}${E3_AG}/newInstall/listPage`,
    method: "post",
    data
  });
}
//初装回访统计报表导出
export function firstInstallReturnVisitStatisticsReportExport(data) {
  return http({
    url: `${host}${E3_AG}/newInstall/newInstall/export`,
    method: "post",
    data
  });
}
//客户满意度回访统计报表
export function customerSatisfactionStatisticsReportList(data) {
  return http({
    url: `${host}${E3_AG}/csr/report/listpage`,
    method: "post",
    data
  });
}
//客户满意度回访统计报表导出
export function customerSatisfactionStatisticsReportExport(data) {
  return http({
    url: `${host}${E3_AG}/csr/report/export`,
    method: "post",
    data
  });
}

//客户满意度回访率统计报表
export function customerSatisfactionReturnVisitRateReportList(data) {
  return http({
    url: `${host}${E3_AG}/csr/rateReport/listpage`,
    method: "post",
    data
  });
}
//客户满意度回访率统计报表导出
export function customerSatisfactionReturnVisitRateReportExport(data) {
  return http({
    url: `${host}${E3_AG}/csr/rateReport/export`,
    method: "post",
    data
  });
}

//客户满意度回访问题列表
export function customerSatisfactionReturnVisitAnswerList(data) {
  return http({
    url: `${host}${E3_AG}/csr/getQuesAndAnswer`,
    method: "post",
    data
  });
}
//初装回访率报表
export function getFirstVisiteRate(data) {
  return http({
    url: `${host}${E3_AG}/newInstall/rate/listPage`,
    method: "post",
    data
  });
}
//导出初装回访率报表
export function exportFirstVisiteRate(data) {
  return http({
    url: `${host}${E3_AG}/newInstall/rate/export`,
    method: "post",
    data
  });
}
//维护回访统计报表
export function getFixRevisit(data) {
  return http({
    url: `${host}${E3_AG}/fixRevisit/statistical`,
    method: "post",
    data
  });
}
//导出维护回访统计报表
export function exportFixRevisit(data) {
  return http({
    url: `${host}${E3_AG}/fixRevisit/export`,
    method: "post",
    data
  });
}
//维护回访率统计报表-工程师回访率
export function getStatisticalEngineerRate(data) {
  return http({
    url: `${host}${E3_AG}/fixRevisit/statisticalEngineerRate`,
    method: "post",
    data
  });
}
//维护回访率统计报表-总回访率
export function getStatisticalTotalRate(data) {
  return http({
    url: `${host}${E3_AG}/fixRevisit/statisticalTotalRate`,
    method: "post",
    data
  });
}
//维护回访率统计报表-客户回访率
export function getStatisticalCorpRate(data) {
  return http({
    url: `${host}${E3_AG}/fixRevisit/statisticalCorpRate`,
    method: "post",
    data
  });
}
//导出维护回访率统计报表
export function exportFixRevisitRate(data) {
  return http({
    url: `${host}${E3_AG}/fixRevisit/rate/export`,
    method: "post",
    data
  });
}
export function getRecycleMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/recycleMaterial/listPage`,
    method: "post",
    data
  });
}
export function exportRecycleMaterial(data) {
  return http({
    url: `${host}${E3_BL_EWOS}/recycleMaterial/export`,
    method: "post",
    data
  });
}
